import { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { format, parseISO } from 'date-fns';
import DoctorInvoiceService from 'src/services/DoctorInvoiceService';
import DoctorService from 'src/services/DoctorService';
import {
  InvoiceType,
  SummaryDoctorInvoiceDTO,
  DoctorInvoicesLogicResponse,
} from './types';

/**
 * Logic hook for the DoctorInvoices component
 */
export const useDoctorInvoicesLogic = (
  doctorId: string,
  initialDoctorFullName?: string
): DoctorInvoicesLogicResponse => {
  // State
  const [filterStartDateRange, setFilterStartDateRange] = useState<Date | null>(
    null
  );
  const [filterEndDateRange, setFilterEndDateRange] = useState<Date | null>(
    null
  );
  const [filterInvoiceType, setFilterInvoiceType] =
    useState<InvoiceType | null>(null);
  const [filterParams, setFilterParams] = useState<any>({});
  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue, setDebouncedSearchValue] = useState('');
  const searchTimeout = useRef<NodeJS.Timeout | null>(null);
  const [rowData, setRowData] = useState<SummaryDoctorInvoiceDTO[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [doctorFullName, setDoctorFullName] = useState(initialDoctorFullName || '');
  
  // Selection state
  const [selectedInvoices, setSelectedInvoices] = useState<string[]>([]);
  const [isDownloadingSelected, setIsDownloadingSelected] = useState(false);

  // Services - memoize to prevent recreation on each render
  const doctorInvoiceService = useMemo(() => new DoctorInvoiceService(), []);
  const doctorService = useMemo(() => new DoctorService(), []);

  // Functions
  const handleRegenerateInvoice = async (
    doctorId: string,
    invoiceReferences: string[]
  ) => {
    try {
      return (
        (await doctorInvoiceService.regenerateInvoice(
          doctorId,
          invoiceReferences
        )) || []
      );
    } catch (error) {
      console.error('Error regenerating invoice:', error);
      throw error;
    }
  };

  const handleSearchChange = (value: string) => {
    setSearchValue(value);

    // Clear previous timeout
    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current);
    }

    // Set new timeout for debounce
    searchTimeout.current = setTimeout(() => {
      setDebouncedSearchValue(value);
    }, 500);
  };

  const getInvoiceTypeInfo = (type: InvoiceType) => {
    switch (type) {
      case InvoiceType.INVOICE:
        return {
          backgroundColor: '#DAF3EE',
          textColor: '#00A389',
          label: 'invoice',
        };
      case InvoiceType.CREDIT_NOTE:
        return {
          backgroundColor: '#E5E9EC',
          textColor: '#647480',
          label: 'credit note',
        };
      default:
        return {
          backgroundColor: '',
          textColor: '',
          label: 'Unknown',
        };
    }
  };

  // Effects
  useEffect(() => {
    const params: any = {};

    if (filterStartDateRange) {
      params.startDate = format(filterStartDateRange, 'yyyy-MM-dd');
    }

    if (filterEndDateRange) {
      params.endDate = format(filterEndDateRange, 'yyyy-MM-dd');
    }

    if (filterInvoiceType !== null) {
      params.type = filterInvoiceType;
    }

    if (debouncedSearchValue) {
      params.query = debouncedSearchValue;
    }

    setFilterParams(params);
  }, [
    filterInvoiceType,
    debouncedSearchValue,
    filterStartDateRange,
    filterEndDateRange,
  ]);

  useEffect(() => {
    const fetchInvoices = async () => {
      if (!doctorId) return;

      setIsLoading(true);
      try {
        const result = await doctorInvoiceService.getInvoices(
          doctorId,
          filterParams
        );
        setRowData(result?.data || []);
      } catch (error) {
        console.error('Error fetching invoices:', error);
        setRowData([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchInvoices();
  }, [doctorId, filterParams, doctorInvoiceService]);

  useEffect(() => {
    // Only fetch doctor info if it wasn't provided as a prop
    if (initialDoctorFullName) return;
    
    const fetchDoctorInfo = async () => {
      if (!doctorId) return;

      try {
        const doctors = await doctorService.get();
        const doctor = doctors.find((doc: any) => doc.id === doctorId);
        if (doctor) {
          setDoctorFullName(`${doctor.firstName} ${doctor.lastName}`);
        }
      } catch (error) {
        console.error('Error fetching doctor info:', error);
      }
    };

    fetchDoctorInfo();
  }, [doctorId, doctorService, initialDoctorFullName]);

  // Selection handlers
  const handleSelectInvoice = useCallback((invoiceId: string, isSelected: boolean) => {
    setSelectedInvoices(prev => {
      if (isSelected) {
        return [...prev, invoiceId];
      } else {
        return prev.filter(id => id !== invoiceId);
      }
    });
  }, []);

  const handleSelectAllInvoices = useCallback((isSelected: boolean) => {
    if (isSelected) {
      const allIds = rowData.map(invoice => invoice.id);
      setSelectedInvoices(allIds);
    } else {
      setSelectedInvoices([]);
    }
  }, [rowData]);

  const handleDownloadSelected = useCallback(async () => {
    if (selectedInvoices.length === 0) return;

    setIsDownloadingSelected(true);
    try {
      // Get the invoice numbers for the selected invoice IDs
      const selectedInvoiceNumbers = rowData
        .filter(invoice => selectedInvoices.includes(invoice.id))
        .map(invoice => invoice.number);

      // Regenerate the invoices
      const result = await handleRegenerateInvoice(doctorId, selectedInvoiceNumbers);

      if (result && Array.isArray(result) && result.length > 0) {
        // Open each PDF in a new tab
        result.forEach(pdfUrl => {
          window.open(pdfUrl, '_blank');
        });
      } else {
        console.error('No PDF URLs returned from the API or empty array received.');
        alert('No PDFs were generated. Please try again or contact support.');
      }
    } catch (error) {
      console.error('Error downloading selected invoices:', error);
      alert('An error occurred while downloading the selected invoices.');
    } finally {
      setIsDownloadingSelected(false);
    }
  }, [selectedInvoices, rowData, doctorId, handleRegenerateInvoice]);

  // Reset selection when data changes
  useEffect(() => {
    setSelectedInvoices([]);
  }, [rowData]);

  return {
    rowData,
    filterEndDateRange,
    filterStartDateRange,
    filterInvoiceType,
    setFilterEndDateRange,
    setFilterStartDateRange,
    setFilterInvoiceType,
    handleSearchChange,
    searchValue,
    getInvoiceTypeInfo,
    doctorFullName,
    isLoading,
    handleRegenerateInvoice,
    selectedInvoices,
    handleSelectInvoice,
    handleSelectAllInvoices,
    handleDownloadSelected,
    isDownloadingSelected,
  };
};
