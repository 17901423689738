import * as React from "react";
import DoctorDetailViewState from "./DoctorDetailViewState";
import { InputView } from "../../../../../components/inputView/InputView";
import { App } from "../../../../../app";
import HeaderView from "../../../components/headerView/HeaderView";
import DoctorDTO from "../../../../../models/DoctorDTO";
import DoctorService from "../../../../../services/DoctorService";
import DoctorDetailViewStyle from "./DoctorDetailViewStyle";
import DetailComponent from "../../../abstracts/detailComponent/DetailComponent";
import SaveButtonView from "../../../components/saveButtonView/SaveButtonView";
import ServiceDTO from "../../../../../models/ServiceDTO";
import DoctorInfoDTO from "../../../../../models/DoctorInfoDTO";
import DoctorInfoService from "../../../../../services/DoctorInfoService";
import ServiceService from "../../../../../services/ServiceService";
import DoctorServiceService from "../../../../../services/DoctorServiceService";
import DetailView from "../../../components/detailView/DetailView";
import TranslationView from "../../../../../components/translationView/TranslationView";
import DoubleClickButtonView from "../../../../../components/buttonView/DoubleClickButtonView";
import DoctorConfirmationService from "../../../../../services/DoctorConfirmationService";
import DoctorStatsView from "./modules/stats/DoctorStatsView";
import DoctorInvoiceView from "./modules/invoices/DoctorInvoiceView";
import DoctorScheduleView from "./modules/schedules/DoctorScheduleView";
import DoctorPricingView from "./modules/pricing/DoctorPricingView";
import DoctorContactView from "./modules/contact/DoctorContactView";
import DoctorGeneralView from "./modules/general/DoctorGeneralView";

export default class DoctorDetailView extends DetailComponent<
  {},
  DoctorDetailViewState
> {
  /* ATTRIBUTES */
  autocomplete: any;
  service = new DoctorService();
  style = new DoctorDetailViewStyle();
  infoService = new DoctorInfoService();
  serviceService = new ServiceService();
  doctorServiceService = new DoctorServiceService();
  requiredFields = [
    "firstName",
    "lastName",
    "gender",
    "officeAddress",
    "mainLanguage",
    "email",
    "inhouseAppreciation",
  ];

  /* CONSTRUCTOR */
  constructor(props: {}) {
    super(props);

    // Initial State
    let doctor = new DoctorDTO();

    doctor.appointmentMethods = [];

    if (App.history.location.state != undefined) {
      doctor = App.history.location.state.doctor;
    }

    this.state = {
      element: doctor,
      errors: {},
      info: new DoctorInfoDTO(),
      services: [],
      isBusy: false,
      fields: [],
    };

    // Handlers
    this.handleSaved = this.handleSaved.bind(this);
    this.mapServices = this.mapServices.bind(this);
    this.checkRequired = this.checkRequired.bind(this);
    this.mapServiceHeaders = this.mapServiceHeaders.bind(this);
    this.handleInputChanged = this.handleInputChanged.bind(this);
    this.handleSuggestionSelected = this.handleSuggestionSelected.bind(this);
    this.handleServicePriceChanged = this.handleServicePriceChanged.bind(this);
    this.handleServiceActiveChanged =
      this.handleServiceActiveChanged.bind(this);
    this.handleSendEncodingConfirmation =
      this.handleSendEncodingConfirmation.bind(this);
  }

  /* LIFE CYCLE EVENTS */
  async componentDidMount() {
    this.handleRefresh();
  }

  /* HANDLERS */
  async handleRefresh() {
    let services = await this.execute<ServiceDTO[]>(this.serviceService.get());

    // Edit
    if (App.history.location.state != undefined) {
      const doctor = this.state.element;
      const doctorInfo = await this.execute(this.infoService.get(doctor.id));
      const doctorServices = await this.execute(
        this.doctorServiceService.get(doctor.id)
      );

      services = services
        .filter((elt: ServiceDTO) => elt.type === 1)
        ?.map((service: ServiceDTO) => {
          const doctorService = doctorServices.find(
            (elt: any) => elt.serviceId === service.id
          );
          if (doctorService != undefined) {
            service.isActive = doctorService.isActive;
            service.price = doctorService.price;
            service.id = doctorService.id;
          }

          service.price = this.mapPrice(service.price);

          return service;
        });

      this.setState({ services, info: doctorInfo });
    }
    // Create
    else {
      this.setState({
        services: services.filter((elt: ServiceDTO) => elt.type === 1),
      });
    }
  }

  async handleSendEncodingConfirmation() {
    await this.handleSaved(false);
    await this.execute(
      new DoctorConfirmationService().post(this.state.element.id)
    );
    this.success(`E-mail confirmation sent.`);
  }

  async handleSaved(navigate: boolean = true) {
    // Destructuring
    const { errors, element } = this.state;

    /* REQUIRED FIELD CHECKS */
    this.requiredFields.forEach((field) => this.checkRequired(field));
    if (
      this.requiredFields.filter((target) => {
        if (errors[target] === true) return target;
        return undefined;
      }).length > 0
    ) {
      this.setState(this.state, () =>
        this.danger("You're missing some information.")
      );
      return;
    }

    /* LONGITUDE AND LATITUDE CHECKS */
    if (!element.longitude || !element.latitude) {
      this.danger("Please select a proposed address.");
      return;
    }

    //Verify if all services are fulfilled
    if (
      this.state.services.filter(
        (elt) => elt.isActive === true || elt.isActive === false
      ).length !== 4
    ) {
      this.danger("Please fill in all the doctor services.");
      return;
    }

    /* SERVER CALL */
    if (element.id) {
      await this.execute(this.service.put(element));
      const doctorServicePromises = this.state.services?.map(
        (service: ServiceDTO) => {
          service.serviceId = service.id;
          service.doctorId = element.id;
          if (service.price) {
            service.price = service.price.replace(" €", "");
          } else {
            service.price = "000.00";
          }
          return this.doctorServiceService.put(element.id, service);
        }
      );
      await this.execute(Promise.all(doctorServicePromises));
      this.success("Doctor saved");
    } else {
      const data = await this.execute(this.service.post(element));
      const doctorServicePromises = this.state.services?.map(
        (service: ServiceDTO) => {
          service.serviceId = service.id;
          service.doctorId = data.id;
          if (service.price) {
            service.price = service.price.replace(" €", "");
          } else {
            service.price = "000.00";
          }
          return this.doctorServiceService.post(data.id, service);
        }
      );
      await this.execute(Promise.all(doctorServicePromises));
      this.success("Doctor created");
    }

    if (navigate) {
      App.history.goBack();
    }
  }

  handleSuggestionSelected(suggestion: any) {
    this.handleInputChanged("officeAddress", suggestion.description);
  }

  handleServiceActiveChanged(element: ServiceDTO, isActive: string) {
    this.setState((previousState) => {
      const service = previousState.services.find(
        (service) => service.id == element.id
      );
      if (service != undefined) {
        service.isActive = isActive === "true" ? true : false;
      }

      previousState.fields.push(element.id);

      return previousState;
    });
  }

  handleServicePriceChanged(element: ServiceDTO, price: string) {
    this.setState((previousState) => {
      const service = previousState.services.find(
        (service) => service.id == element.id
      );
      if (service != undefined) {
        service.price = price;
      }

      previousState.fields.push(element.id);

      return previousState;
    });
  }

  /* METHODS */
  mapPrice(price: string) {
    // Little hack to be certain price is a string
    price = `${price}`;

    if (price && price !== "___.__ €") {
      const splitPrice = price.replace(" €", "").replace(/_/g, "").split(".");

      if (splitPrice && splitPrice.length > 0) {
        for (var i = 0; i < 3 - splitPrice[0].length; i++) {
          price = "0" + price;
        }

        if (splitPrice[1]) {
          for (var i = 0; i < 2 - splitPrice[1].length; i++) {
            price = price + "0";
          }
        } else {
          price = price + ".00";
        }
      } else {
        price = "000.00";
      }
    }

    return price;
  }

  mapServiceHeaders() {
    return (
      <tr>
        <th>
          <TranslationView>doctorServiceName</TranslationView>
        </th>
        <th>
          <TranslationView>doctorServiceActive</TranslationView>
        </th>
        <th>
          <TranslationView>doctorServicePrice</TranslationView>
        </th>
      </tr>
    );
  }

  mapServices(service: ServiceDTO, index: number) {
    return (
      <tr key={index}>
        <th>
          <span style={this.style.service}>{service.name}</span>
        </th>
        <th>
          <InputView
            placeholder="Select an option"
            type="select"
            value={`${service.isActive}`}
            onChange={(event) =>
              this.handleServiceActiveChanged(service, event.target.value)
            }
          >
            <option value="true">Yes</option>
            <option value="false">No</option>
          </InputView>
        </th>
        <th>
          <InputView
            placeholder="0.00€"
            mask="999.99 €"
            type="text"
            value={service.price}
            onChange={(event) =>
              this.handleServicePriceChanged(service, event.target.value)
            }
          />
        </th>
      </tr>
    );
  }

  /* RENDERING */
  render() {
    const { element } = this.state;
    const illustration =
      App.history.location.state != undefined
        ? "/assets/illu_doctor_edit.png"
        : "/assets/illu_doctor_new.png";

    let title = "New Doctor";
    let history = [
      { name: "Doctors", path: "/dashboard/doctor" },
      {
        name: "New Doctor",
        path: "/dashboard/doctor/detail",
        state: undefined,
      },
    ];

    if (App.history.location.state != undefined) {
      title = `${App.history.location.state.doctor.firstName} ${App.history.location.state.doctor.lastName}`;
      history = [
        { name: "Doctors", path: "/dashboard/doctor" },
        {
          name: title,
          path: "/dashboard/doctor/detail",
          state: App.history.location.state,
        },
      ];
    }
    function handleInvoiceButtonClick() {
      App.history.push(`/dashboard/doctor/invoices/${element.id}`, {
        doctorFullName: `${element.firstName} ${element.lastName}`
      });
    }

    return (
      <DetailView
        fields={this.state.fields}
        handleSaved={() => this.handleSaved(false)}
        className="col"
        style={{ ...this.style.container, marginTop: 0 }}
      >
        {/* PAGE ILLUSTRATION */}
        <img src={illustration} style={this.style.doctorIllustration} />

        {/* PAGE HEADER */}
        <div style={this.style.headerContainer}>
          <HeaderView title={title} history={history} />
          <div style={{ alignSelf: "center" }}>
            <button
              onClick={handleInvoiceButtonClick}
              type="button"
              className="btn btn-primary"
            >
              Invoices
            </button>
          </div>
        </div>

        {/* PAGE CONTENT */}
        <div>
          {/* GENERAL INFORMATION */}
          <DoctorGeneralView
            element={element}
            handleInputChanged={this.handleInputChanged}
            errors={this.state.errors}
            style={this.style}
          />

          {/* CONTACT INFORMATION */}
          <DoctorContactView
            element={element}
            handleInputChanged={this.handleInputChanged}
            errors={this.state.errors}
            style={this.style}
          />

          {/* SCHEDULE */}
          <DoctorScheduleView
            element={element}
            handleInputChanged={this.handleInputChanged}
            style={this.style}
          />

          {/* Invoicing Information */}
          <DoctorInvoiceView
            element={element}
            handleInputChanged={this.handleInputChanged}
            errors={this.state.errors}
            style={this.style}
          />

          {/* PRICING INFORMATION */}
          <DoctorPricingView
            services={this.state.services}
            style={this.style}
            mapServices={this.mapServices}
            mapServiceHeaders={this.mapServiceHeaders}
          />

          {/* STATISTICS */}
          <DoctorStatsView
            element={element}
            information={this.state.info}
            handleInputChanged={this.handleInputChanged}
            errors={this.state.errors}
            style={this.style}
          />

          {/* SAVE BUTTON */}
          <div style={this.style.buttonContainer}>
            <DoubleClickButtonView
              isBusy={this.state.isBusy}
              onClick={this.handleSendEncodingConfirmation}
              width={300}
              image="icon_double_confirmation.svg"
            >
              sendEncodingConfirmation
            </DoubleClickButtonView>
            <SaveButtonView
              isBusy={this.state.isBusy}
              handleSaved={this.handleSaved}
            />
          </div>
        </div>
      </DetailView>
    );
  }
}
