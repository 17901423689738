import Service from './Service';
import Axios from 'axios';

export default class DoctorInvoiceService extends Service
{
    /**
     * Regenerates invoices for a specific doctor
     * @param doctorId The ID of the doctor
     * @param invoiceReferences Array of invoice references to regenerate
     * @returns Promise with array of links to regenerated invoice PDFs
     */
    regenerateInvoice(doctorId: string, invoiceReferences: string[])
    {
        return this.execute(
            Axios.post(
                `${this.url}/doctors/${doctorId}/regenerate-invoice`,
                invoiceReferences,
                this.config()
            )
        );
    }

    /**
     * Gets invoices for a specific doctor
     * @param doctorId The ID of the doctor
     * @param params Optional query parameters for filtering
     * @returns Promise with array of invoice data
     */
    getInvoices(doctorId: string, params: any = {})
    {
        return this.execute(
            Axios.get(
                `${this.url}/doctors/${doctorId}/invoices`,
                { 
                    ...this.config(),
                    params
                }
            )
        );
    }
}
