import React, { useState } from 'react';
import { FilterMenuProps } from '../types';
import DateRangeFilter from './DateRangeFilter';
import TypeInvoiceFilter from './TypeInvoiceFilter';

/**
 * Component for displaying the filter menu
 */
const FilterMenu: React.FC<FilterMenuProps> = ({
  filterEndDateRange,
  filterStartDateRange,
  filterInvoiceType,
  setFilterEndDateRange,
  setFilterStartDateRange,
  setFilterInvoiceType,
  getInvoiceTypeInfo,
}) => {
  const [showDateFilter, setShowDateFilter] = useState(false);
  const [showTypeFilter, setShowTypeFilter] = useState(false);

  // Calculate active filter count
  const activeFilterCount = [
    filterStartDateRange,
    filterEndDateRange,
    filterInvoiceType,
  ].filter(Boolean).length;

  // Get style based on filter count
  const getStyleByFilterCount = (count: number) => {
    if (count > 0) {
      return {
        iconFill: '#0066CC',
        borderColor: '#0066CC',
        color: '#0066CC',
        filterText: `Filter (${count})`,
      };
    }
    return {
      iconFill: '#323A40',
      borderColor: '#E5E5E5',
      color: '#323A40',
      filterText: 'Filter',
    };
  };

  const style = getStyleByFilterCount(activeFilterCount);

  // Handle filter close events
  const handleDateRangeFilterClose = () => setShowDateFilter(false);
  const handleTypeFilterClose = () => setShowTypeFilter(false);

  return (
    <div style={{ position: 'relative' }}>
      <button
        onClick={() => setShowTypeFilter(!showTypeFilter)}
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '0.5rem 1rem',
          backgroundColor: '#F5FAFF',
          border: `1px solid ${style.borderColor}`,
          borderRadius: '0.25rem',
          color: style.color,
          fontWeight: 600,
          cursor: 'pointer',
          marginRight: '0.5rem',
        }}
      >
        <svg
          width='16'
          height='16'
          viewBox='0 0 16 16'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          style={{ marginRight: '0.5rem' }}
        >
          <path
            d='M15 2H1L6.5 8.5V13L9.5 14.5V8.5L15 2Z'
            stroke={style.iconFill}
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
        {style.filterText}
      </button>

      {showTypeFilter && (
        <div
          style={{
            position: 'absolute',
            top: '100%',
            left: 0,
            zIndex: 10,
            marginTop: '0.5rem',
          }}
        >
          <div
            style={{
              backgroundColor: 'white',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
              borderRadius: '0.25rem',
              padding: '0.5rem 0',
              minWidth: '200px',
            }}
          >
            <div
              onClick={() => {
                setShowTypeFilter(false);
                setShowDateFilter(true);
              }}
              style={{
                padding: '0.5rem 1rem',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <svg
                width='16'
                height='16'
                viewBox='0 0 16 16'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                style={{ marginRight: '0.5rem' }}
              >
                <path
                  d='M13 2H3C1.89543 2 1 2.89543 1 4V14C1 15.1046 1.89543 16 3 16H13C14.1046 16 15 15.1046 15 14V4C15 2.89543 14.1046 2 13 2Z'
                  stroke='#323A40'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M5 0V4'
                  stroke='#323A40'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M11 0V4'
                  stroke='#323A40'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M1 8H15'
                  stroke='#323A40'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
              Date(s)
            </div>
            <div
              onClick={() => {
                setShowTypeFilter(false);
                setShowTypeFilter(true);
              }}
              style={{
                padding: '0.5rem 1rem',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <svg
                width='16'
                height='16'
                viewBox='0 0 16 16'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                style={{ marginRight: '0.5rem' }}
              >
                <path
                  d='M14 2H2C1.44772 2 1 2.44772 1 3V13C1 13.5523 1.44772 14 2 14H14C14.5523 14 15 13.5523 15 13V3C15 2.44772 14.5523 2 14 2Z'
                  stroke='#323A40'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M1 6H15'
                  stroke='#323A40'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
              Type
            </div>
          </div>
        </div>
      )}

      {showDateFilter && (
        <DateRangeFilter
          filterEndDateRange={filterEndDateRange}
          filterStartDateRange={filterStartDateRange}
          setFilterEndDateRange={setFilterEndDateRange}
          setFilterStartDateRange={setFilterStartDateRange}
          onFilterClose={handleDateRangeFilterClose}
        />
      )}

      {showTypeFilter && (
        <TypeInvoiceFilter
          filterInvoiceType={filterInvoiceType}
          setFilterInvoiceType={setFilterInvoiceType}
          getInvoiceTypeInfo={getInvoiceTypeInfo}
          onFilterClose={handleTypeFilterClose}
        />
      )}
    </div>
  );
};

export default FilterMenu;
