import React from 'react';
import { TypeInvoiceFilterProps, InvoiceType } from '../types';

/**
 * Component for filtering by invoice type
 */
const TypeInvoiceFilter: React.FC<TypeInvoiceFilterProps> = ({
  filterInvoiceType,
  setFilterInvoiceType,
  getInvoiceTypeInfo,
  onFilterClose,
}) => {
  return (
    <div
      style={{
        position: 'absolute',
        top: '100%',
        left: 0,
        zIndex: 10,
        marginTop: '0.5rem',
      }}
    >
      <div
        style={{
          backgroundColor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
          borderRadius: '0.25rem',
          padding: '1rem',
          minWidth: '250px',
        }}
      >
        <div style={{ marginBottom: '1rem' }}>
          <div style={{ marginBottom: '0.5rem', fontWeight: 600 }}>Invoice Type</div>
          
          <div style={{ marginBottom: '0.5rem' }}>
            <label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
              <input
                type="radio"
                checked={filterInvoiceType === null}
                onChange={() => setFilterInvoiceType(null)}
                style={{ marginRight: '0.5rem' }}
              />
              All
            </label>
          </div>
          
          <div style={{ marginBottom: '0.5rem' }}>
            <label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
              <input
                type="radio"
                checked={filterInvoiceType === InvoiceType.INVOICE}
                onChange={() => setFilterInvoiceType(InvoiceType.INVOICE)}
                style={{ marginRight: '0.5rem' }}
              />
              <div 
                style={{ 
                  display: 'inline-block',
                  padding: '0.25rem 0.75rem',
                  borderRadius: '1rem',
                  backgroundColor: getInvoiceTypeInfo(InvoiceType.INVOICE).backgroundColor,
                  color: getInvoiceTypeInfo(InvoiceType.INVOICE).textColor,
                  fontWeight: 600,
                  fontSize: '0.875rem',
                  textTransform: 'capitalize'
                }}
              >
                {getInvoiceTypeInfo(InvoiceType.INVOICE).label}
              </div>
            </label>
          </div>
          
          <div style={{ marginBottom: '0.5rem' }}>
            <label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
              <input
                type="radio"
                checked={filterInvoiceType === InvoiceType.CREDIT_NOTE}
                onChange={() => setFilterInvoiceType(InvoiceType.CREDIT_NOTE)}
                style={{ marginRight: '0.5rem' }}
              />
              <div 
                style={{ 
                  display: 'inline-block',
                  padding: '0.25rem 0.75rem',
                  borderRadius: '1rem',
                  backgroundColor: getInvoiceTypeInfo(InvoiceType.CREDIT_NOTE).backgroundColor,
                  color: getInvoiceTypeInfo(InvoiceType.CREDIT_NOTE).textColor,
                  fontWeight: 600,
                  fontSize: '0.875rem',
                  textTransform: 'capitalize'
                }}
              >
                {getInvoiceTypeInfo(InvoiceType.CREDIT_NOTE).label}
              </div>
            </label>
          </div>
        </div>
        
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <button
            onClick={() => {
              setFilterInvoiceType(null);
            }}
            style={{
              padding: '0.5rem 1rem',
              backgroundColor: 'white',
              border: '1px solid #E5E5E5',
              borderRadius: '0.25rem',
              cursor: 'pointer',
            }}
          >
            Clear
          </button>
          <button
            onClick={onFilterClose}
            style={{
              padding: '0.5rem 1rem',
              backgroundColor: '#0066CC',
              color: 'white',
              border: 'none',
              borderRadius: '0.25rem',
              cursor: 'pointer',
            }}
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};

export default TypeInvoiceFilter;
