import React from 'react';
import { DateRangeFilterProps } from '../types';

/**
 * Component for filtering by date range
 */
const DateRangeFilter: React.FC<DateRangeFilterProps> = ({
  filterEndDateRange,
  filterStartDateRange,
  setFilterEndDateRange,
  setFilterStartDateRange,
  onFilterClose,
}) => {
  return (
    <div
      style={{
        position: 'absolute',
        top: '100%',
        left: 0,
        zIndex: 10,
        marginTop: '0.5rem',
      }}
    >
      <div
        style={{
          backgroundColor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
          borderRadius: '0.25rem',
          padding: '1rem',
          minWidth: '300px',
        }}
      >
        <div style={{ marginBottom: '1rem' }}>
          <div style={{ marginBottom: '0.5rem', fontWeight: 600 }}>Start Date</div>
          <input
            type="date"
            value={filterStartDateRange ? filterStartDateRange.toISOString().split('T')[0] : ''}
            onChange={(e) => {
              const date = e.target.value ? new Date(e.target.value) : null;
              setFilterStartDateRange(date);
            }}
            style={{
              width: '100%',
              padding: '0.5rem',
              border: '1px solid #E5E5E5',
              borderRadius: '0.25rem',
            }}
          />
        </div>
        
        <div style={{ marginBottom: '1rem' }}>
          <div style={{ marginBottom: '0.5rem', fontWeight: 600 }}>End Date</div>
          <input
            type="date"
            value={filterEndDateRange ? filterEndDateRange.toISOString().split('T')[0] : ''}
            onChange={(e) => {
              const date = e.target.value ? new Date(e.target.value) : null;
              setFilterEndDateRange(date);
            }}
            style={{
              width: '100%',
              padding: '0.5rem',
              border: '1px solid #E5E5E5',
              borderRadius: '0.25rem',
            }}
          />
        </div>
        
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <button
            onClick={() => {
              setFilterStartDateRange(null);
              setFilterEndDateRange(null);
            }}
            style={{
              padding: '0.5rem 1rem',
              backgroundColor: 'white',
              border: '1px solid #E5E5E5',
              borderRadius: '0.25rem',
              cursor: 'pointer',
            }}
          >
            Clear
          </button>
          <button
            onClick={onFilterClose}
            style={{
              padding: '0.5rem 1rem',
              backgroundColor: '#0066CC',
              color: 'white',
              border: 'none',
              borderRadius: '0.25rem',
              cursor: 'pointer',
            }}
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};

export default DateRangeFilter;
