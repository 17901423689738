import Service from './Service';
import Axios from 'axios';
import SuperiorDTO from '../models/SuperiorDTO';

export default class CompanySuperiorService extends Service {
  // Keep the original method for backward compatibility
  get(id: String) {
    return this.execute(
      Axios.get(
        `${this.url}/companies/${id}/superiors?size=2000`,
        this.config()
      )
    );
  }

  // Add a new method for paginated requests
  getWithPaging(
    id: String,
    page: number = 0,
    size: number = 50,
    search: string = ''
  ) {
    const searchParam = search ? `&search=${encodeURIComponent(search)}` : '';
    return this.execute(
      Axios.get(
        `${this.url}/companies/${id}/superiors?page=${page}&size=${size}${searchParam}`,
        this.config()
      )
    );
  }

  addBulk(companyId: string, superiors: SuperiorDTO[]) {
    return this.execute(
      Axios.post(
        `${this.url}/companies/${companyId}/batch-superiors`,
        {
          companyId,
          superiors,
        },
        this.config()
      ),
      'Unable to add superiors, please check your CSV file.'
    );
  }
}
