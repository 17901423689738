import * as React from 'react';
import SuperiorViewState from './SuperiorViewState';
import CompanySuperiorService from '../../../../../../../../services/CompanySuperiorService';
import { App } from '../../../../../../../../app';
import SearchableComponent from '../../../../../../abstracts/searchableComponent/SearchableComponent';
import SearchView from '../../../../../../components/searchView/SearchView';
import ListView from '../../../../../../components/listView/ListView';
import TranslationView from '../../../../../../../../components/translationView/TranslationView';
import SuperiorViewStyle from './SuperiorViewStyle';
import CompanyNavView from '../../../../components/companyNavView/index';
import { LocalizationContext } from 'src/components/localizationContext/LocalizationContext';
import SuperiorUploadButton from './components/upload-button';

export default class SuperiorView extends SearchableComponent<
  {},
  SuperiorViewState
> {
  /* ATTRIBUTES */
  style = new SuperiorViewStyle();
  service = new CompanySuperiorService();
  searchableValues = ['firstName', 'lastName', 'email'];
  searchTimeout: NodeJS.Timeout | null = null;
  
  // Cache configuration
  cacheTTL = 60000; // Cache time-to-live in milliseconds (1 minute)
  cacheMaxSize = 20; // Maximum number of cache entries

  /* CONSTRUCTOR */
  constructor(props: {}) {
    super(props);

    // Initial State
    this.state = {
      editHovered: false,
      currentSuperior: '',
      elements: [],
      page: 0,
      search: '',
      isBusy: false,
      totalElements: 0,
      pageSize: 50,
      totalPages: 0,
      isServerSidePagination: false,
      cacheData: {}, // Initialize empty cache
    };

    // Handlers
    this.handleNew = this.handleNew.bind(this);
    this.mapSuperiors = this.mapSuperiors.bind(this);
    this.handleEditHovered = this.handleEditHovered.bind(this);
    this.handleSearchChanged = this.handleSearchChanged.bind(this);
    this.handleCurrentSuperior = this.handleCurrentSuperior.bind(this);
    this.handlePageChanged = this.handlePageChanged.bind(this);
    
    // Cache methods
    this.generateCacheKey = this.generateCacheKey.bind(this);
    this.getCachedData = this.getCachedData.bind(this);
    this.setCachedData = this.setCachedData.bind(this);
    this.clearCache = this.clearCache.bind(this);
    this.isCacheValid = this.isCacheValid.bind(this);
  }

  /* LIFE CYCLE EVENTS */
  async componentDidMount() {
    await this.loadSuperiors();
  }

  /* DATA LOADING */
  async loadSuperiors() {
    this.setState({ isBusy: true });
    try {
      const companyId = App.history.location.state.company.id;
      const pageSize = this.state.pageSize;
      const page = this.state.page;
      const search = this.state.search;

      // Check if we have this data in the cache
      const cachedData = this.getCachedData(companyId, page, pageSize, search);
      
      if (cachedData) {
        console.log('Using cached data for superiors');
        // Use the cached data
        this.setState({
          elements: cachedData.data,
          isServerSidePagination: true,
          totalElements: cachedData.totalElements,
          totalPages: cachedData.totalPages,
          isBusy: false // Set isBusy to false immediately since we're using cached data
        });
        return; // Exit early since we're using cached data
      }

      // If not in cache or cache is invalid, fetch from API
      console.log('Fetching superiors from API');
      const paginatedResult = await this.execute(
        this.service.getWithPaging(companyId, page, pageSize, search)
      );

      // The paginated result should include total count information
      if (paginatedResult && paginatedResult.data) {
        // Calculate total elements and pages
        const totalElements = paginatedResult.totalAmount || paginatedResult.data.length;
        const totalPages = paginatedResult.nbPages || Math.ceil(paginatedResult.data.length / pageSize);
        
        // Store in cache
        this.setCachedData(
          companyId,
          page,
          pageSize,
          search,
          paginatedResult.data,
          totalElements,
          totalPages
        );
        
        // Set the elements from the paginated result
        this.setState({
          elements: paginatedResult.data,
          isServerSidePagination: true,
          totalElements: totalElements,
          totalPages: totalPages,
        });
      } else {
        // Handle empty result
        this.setState({
          elements: [],
          isServerSidePagination: true,
          totalElements: 0,
          totalPages: 0,
        });
      }
    } catch (error) {
      console.error('Error loading superiors:', error);
      // In case of error, set empty state
      this.setState({
        elements: [],
        isServerSidePagination: true,
        totalElements: 0,
        totalPages: 0,
      });
    } finally {
      this.setState({ isBusy: false });
    }
  }

  /* PAGINATION HANDLING */
  async handlePageChanged(page: number) {
    if (this.state.isServerSidePagination) {
      // For server-side pagination, we need to fetch the data for the new page
      this.setState({ page }, () => {
        this.loadSuperiors();
      });
    } else {
      // For client-side pagination, just update the page
      this.setState({ page });
    }
  }

  /* HANDLERS */
  handleNew() {
    App.history.push(
      '/dashboard/company/superior/detail',
      App.history.location.state
    );
  }

  handleEditHovered() {
    this.setState({ editHovered: !this.state.editHovered });
  }

  handleCurrentSuperior(superior: any) {
    this.setState({ currentSuperior: superior.id });
  }

  // Override the handleSearchChanged method from SearchableComponent
  handleSearchChanged(event: any, dontUpdatePage?: boolean) {
    // Update the search term immediately in the state for UI feedback
    const searchValue = event.target.value;
    
    if (this.state.isServerSidePagination) {
      // For server-side pagination, use debounce to prevent too many API calls
      
      // Clear any existing timeout
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      
      // Update the search term in the state immediately (for UI feedback)
      this.setState({ search: searchValue, page: 0 });
      
      // Set a new timeout for the actual API call (300ms debounce)
      this.searchTimeout = setTimeout(() => {
        // Only load if the component is still mounted and the search term hasn't changed again
        if (this.state.search === searchValue) {
          this.loadSuperiors();
        }
      }, 300);
    } else {
      // For client-side pagination, use the parent class implementation
      super.handleSearchChanged(event, dontUpdatePage);
    }
  }
  
  // Clean up the timeout when the component unmounts
  componentWillUnmount() {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
  }

  /* METHODS */
  mapHeaders() {
    return (
      <tr>
        <th scope='col'>
          <TranslationView>superiorName</TranslationView>
        </th>
        <th scope='col'>
          <TranslationView>superiorEmail</TranslationView>
        </th>
        <th scope='col'>
          <TranslationView>superiorPhone</TranslationView>
        </th>
        <th scope='col'>
          <TranslationView>superiorLanguage</TranslationView>
        </th>
        <th scope='col' style={{ width: 70 }}></th>
      </tr>
    );
  }

  mapSuperiors(superior: any, index: number) {
    return (
      <tr onMouseEnter={() => this.handleCurrentSuperior(superior)} key={index}>
        <td style={this.style.td} onCopy={this.handleCopied}>
          {`${superior.firstName} ${superior.lastName}`}
        </td>
        <td style={this.style.td} onCopy={this.handleCopied}>
          {superior.email}
        </td>
        <td style={this.style.td} onCopy={this.handleCopied}>
          {superior.phoneNumber}
        </td>
        <td style={this.style.td} onCopy={this.handleCopied}>
          {superior.language}
        </td>
        <td>
          <span
            onMouseEnter={this.handleEditHovered}
            onMouseLeave={this.handleEditHovered}
            onClick={() =>
              App.history.push('/dashboard/company/superior/detail', {
                company: App.history.location.state.company,
                superior: superior,
              })
            }
            style={{
              width: 30,
              height: 30,
              fill: 'white',
              color: 'white',
              margin: 5,
              cursor: 'pointer',
            }}
          >
            <img
              src={`/assets/edit_company${
                this.state.editHovered &&
                this.state.currentSuperior === superior.id
                  ? '_selected'
                  : ''
              }.svg`}
              style={{
                width: 30,
                height: 30,
                fill: 'white',
                color: 'white',
                margin: 5,
                cursor: 'pointer',
              }}
            />
          </span>
        </td>
      </tr>
    );
  }

  /* RENDERING */
  render() {
    return (
      <LocalizationContext.Consumer>
        {({ translate }) => (
          <SearchView
            headerChildren={this.renderCompanyHeader()}
            handleNew={this.handleNew}
            showSuperiorUpload
            onSuperiorUploadComplete={() => {
              // Clear the cache when new superiors are uploaded
              this.clearCache();
              this.loadSuperiors();
            }}
            search={this.state.search}
            handleSearchChanged={this.handleSearchChanged}
            title={`${App.history.location.state.company.name}'s ${translate(
              'superiors'
            )}`}
            history={[
              { name: translate('companies'), path: '/dashboard/company' },
              {
                name: App.history.location.state.company.name,
                path: '/dashboard/company/detail',
                state: App.history.location.state,
              },
              {
                name: translate('superiors'),
                path: '/dashboard/company/superior',
                state: App.history.location.state,
              },
            ]}
          >
            <ListView
              isBusy={this.state.isBusy}
              mapElements={this.mapSuperiors}
              mapHeaders={this.mapHeaders}
              elements={this.state.elements}
              page={this.state.page}
              handlePageChanged={this.handlePageChanged}
            />
          </SearchView>
        )}
      </LocalizationContext.Consumer>
    );
  }

  /* CACHE METHODS */
  
  // Generate a cache key based on request parameters
  generateCacheKey(companyId: string, page: number, size: number, search: string): string {
    return `${companyId}_${page}_${size}_${search}`;
  }
  
  // Check if a cache entry is still valid
  isCacheValid(cacheEntry: any): boolean {
    if (!cacheEntry) return false;
    
    const now = Date.now();
    return (now - cacheEntry.timestamp) < this.cacheTTL;
  }
  
  // Get data from cache if it exists and is valid
  getCachedData(companyId: string, page: number, size: number, search: string): any {
    const cacheKey = this.generateCacheKey(companyId, page, size, search);
    const cacheEntry = this.state.cacheData?.[cacheKey];
    
    if (cacheEntry && this.isCacheValid(cacheEntry)) {
      return cacheEntry;
    }
    
    return null;
  }
  
  // Store data in the cache
  setCachedData(companyId: string, page: number, size: number, search: string, data: any[], totalElements: number, totalPages: number): void {
    const cacheKey = this.generateCacheKey(companyId, page, size, search);
    
    // Create a new cache object to avoid mutating state directly
    const newCacheData = { ...this.state.cacheData };
    
    // Add the new cache entry
    newCacheData[cacheKey] = {
      data,
      timestamp: Date.now(),
      totalElements,
      totalPages
    };
    
    // If the cache is too large, remove the oldest entries
    const cacheKeys = Object.keys(newCacheData);
    if (cacheKeys.length > this.cacheMaxSize) {
      // Sort by timestamp (oldest first)
      cacheKeys.sort((a, b) => newCacheData[a].timestamp - newCacheData[b].timestamp);
      
      // Remove oldest entries until we're under the limit
      const keysToRemove = cacheKeys.slice(0, cacheKeys.length - this.cacheMaxSize);
      keysToRemove.forEach(key => {
        delete newCacheData[key];
      });
    }
    
    // Update the cache in state
    this.setState({ cacheData: newCacheData });
  }
  
  // Clear the entire cache
  clearCache(): void {
    this.setState({ cacheData: {} });
  }

  renderCompanyHeader() {
    return (
      <>
        <CompanyNavView
          type={'superiors'}
          company={App.history.location.state.company}
        />
        {/* <SuperiorUploadButton onUploadComplete={() => this.loadSuperiors()} /> */}
      </>
    );
  }
}
