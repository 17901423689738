import * as React from 'react';
import ListViewProps from './ListViewProps';
import { TableView } from '../tableView/TableView';
import ListViewState from './ListViewState';
import Lottie from 'lottie-react-web';
import * as loader from './assets/loading.json';
import BaseComponent from '../../../../abstracts/BaseComponent';
import TranslationView from '../../../../components/translationView/TranslationView';
import ListViewStyle from './ListViewStyle';

export default class ListView extends BaseComponent<
	ListViewProps,
	ListViewState
> {
	/* ATTRIBUTES */
	style = new ListViewStyle();

	/* CONSTRUCTOR */
	constructor(props: ListViewProps) {
		super(props);

		// Initial State
		this.state = { page: this.props.page ? this.props.page : 0, isBusy: false };

		// Bindings
		this.handlePageChanged = this.handlePageChanged.bind(this);
	}

	/* LIFE CYCLE EVENTS */
	componentDidMount() {}
	
	componentDidUpdate(prevProps: ListViewProps) {
		// Update internal state when page prop changes
		if (this.props.page !== undefined && this.props.page !== prevProps.page) {
			this.setState({ page: this.props.page });
		}
	}

	/* HANDLERS */
	handlePageChanged(sign: string) {
		const { page } = this.state;
		const { length } = this.props.elements;
		const maxNbOfPages = length / 8;

		if (sign === '<') {
			if (page > 0) {
				this.setState(
					(previousState) => {
						return { page: previousState.page - 1 };
					},
					() => {
						if (this.props.handlePageChanged) {
							this.props.handlePageChanged(this.state.page);
						}
					}
				);
			}
		} else if (sign === '>') {
			if (page < maxNbOfPages - 1) {
				this.setState(
					(previousState) => {
						return { page: previousState.page + 1 };
					},
					() => {
						if (this.props.handlePageChanged) {
							this.props.handlePageChanged(this.state.page);
						}
					}
				);
			}
		} else {
			this.setState({ page: parseInt(sign) }, () => {
				if (this.props.handlePageChanged) {
					this.props.handlePageChanged(this.state.page);
				}
			});
		}
	}

	/* METHODS */

	/* RENDERING */
	render() {
		const pages = [];
		const { page } = this.state;
		const {
			elements,
			isBusy,
			mapElements,
			overflow,
			mapHeaders,
			noPaging,
			children,
			hasBusyIndicator,
		} = this.props;

		// Paging LOGIC
		// TODO : Check if refactoring is possible
		let start = Math.floor(this.state.page / 5) * 5;

		if (this.state.page % 5 == 0) {
			start = this.state.page;
		}

		// State update moved to componentDidUpdate for better synchronization

		pages.push('<');

		for (
			var i = start;
			i < this.props?.elements?.length / 8 && i < start + 5;
			i++
		) {
			pages.push('' + i);
		}

		pages.push('>');

		return (
			<>
				{elements.length > 0 && (this.props.noHideLoading ? true : !isBusy) && (
					<TableView
						overflow={overflow}
						values={elements}
						map={mapElements}
						page={page}
					>
						{mapHeaders()}
					</TableView>
				)}

				{elements.length == 0 && !isBusy && (
					<div style={this.style.noResultFound}></div>
				)}

				{elements.length > 0 && !noPaging && !isBusy && elements.length > 8 && (
					<div style={this.style.pagesContainer}>
						{pages?.map((sign, index) => {
							if (sign != '<' && sign != '>') {
								if (page == parseInt(sign)) {
									return (
										<div key={index} style={this.style.pageNumber}>
											{parseInt(sign) + 1}
										</div>
									);
								}
							}

							if (sign == '<' || sign == '>') {
								return (
									<div
										key={index}
										onClick={() => this.handlePageChanged(sign)}
										style={this.style.currentPage}
									>
										{sign == '<' ? (
											<img
												src='/assets/icon_arrow.svg'
												style={{
													transform: 'rotate(90deg)',
													marginLeft: -1,
													marginTop: 2,
													width: '60%',
												}}
											/>
										) : (
											<img
												src='/assets/icon_arrow.svg'
												style={{
													transform: 'rotate(270deg)',
													marginLeft: -0.5,
													marginTop: 1,
													width: '60%',
												}}
											/>
										)}
									</div>
								);
							}

							return (
								<div
									key={index}
									onClick={() => this.handlePageChanged(sign)}
									style={{
										...this.style.pages,
										paddingLeft: parseInt(sign) + 1 > 9 ? 6.5 : 9.5,
									}}
								>
									{parseInt(sign) + 1}
								</div>
							);
						})}
					</div>
				)}

				{isBusy && hasBusyIndicator && (
					<div style={{ padding: 20, textAlign: 'center', width: '100%' }}>
						<img style={{ width: 75 }} src='/assets/loading-list.gif' />
					</div>
				)}
				{children}
			</>
		);
	}
}
