import React from 'react';
import { TypeCellProps } from '../types';

/**
 * Component for displaying the invoice type in the table
 */
const TypeCell: React.FC<TypeCellProps> = ({ invoice, getInvoiceTypeInfo }) => {
    const typeInfo = getInvoiceTypeInfo(invoice.type);
    
    return (
        <div 
            style={{ 
                display: 'inline-block',
                padding: '0.25rem 0.75rem',
                borderRadius: '1rem',
                backgroundColor: typeInfo.backgroundColor,
                color: typeInfo.textColor,
                fontWeight: 600,
                fontSize: '0.875rem',
                textTransform: 'capitalize'
            }}
        >
            {typeInfo.label}
        </div>
    );
};

export default TypeCell;
