import CompanyDTO from '../models/CompanyDTO';

export default class LocalStorageService {
  private static readonly RECENT_SEARCHES_KEY = 'medicheck_recent_searches';
  private static readonly RECENT_COMPANIES_KEY = 'medicheck_recent_companies';
  private static readonly MAX_ITEMS = 5;

  // Save recent search
  static saveRecentSearch(search: string): void {
    if (!search.trim()) return;
    
    const searches = this.getRecentSearches();
    // Remove if exists (to move to top)
    const filteredSearches = searches.filter(s => s !== search);
    // Add to beginning
    filteredSearches.unshift(search);
    // Limit to MAX_ITEMS
    const limitedSearches = filteredSearches.slice(0, this.MAX_ITEMS);
    
    localStorage.setItem(this.RECENT_SEARCHES_KEY, JSON.stringify(limitedSearches));
  }

  // Get recent searches
  static getRecentSearches(): string[] {
    const searches = localStorage.getItem(this.RECENT_SEARCHES_KEY);
    return searches ? JSON.parse(searches) : [];
  }

  // Save recent company
  static saveRecentCompany(company: CompanyDTO): void {
    if (!company || !company.id) return;
    
    const companies = this.getRecentCompanies();
    // Remove if exists (to move to top)
    const filteredCompanies = companies.filter(c => c.id !== company.id);
    
    // Create a simplified company object to save storage space
    const simplifiedCompany = {
      id: company.id,
      name: company.name,
      address: company.address,
      companyNumber: company.companyNumber,
      hasMyCertificate: company.hasMyCertificate
    };
    
    // Add to beginning
    filteredCompanies.unshift(simplifiedCompany);
    // Limit to MAX_ITEMS
    const limitedCompanies = filteredCompanies.slice(0, this.MAX_ITEMS);
    
    localStorage.setItem(this.RECENT_COMPANIES_KEY, JSON.stringify(limitedCompanies));
  }

  // Get recent companies
  static getRecentCompanies(): any[] {
    const companies = localStorage.getItem(this.RECENT_COMPANIES_KEY);
    return companies ? JSON.parse(companies) : [];
  }
}
