import React, { useState } from 'react';
import { ActionCellProps } from '../types';

/**
 * Component for displaying the action buttons in the table
 */
const ActionCell: React.FC<ActionCellProps> = ({
  link,
  invoiceNumber,
  doctorId,
  onRegenerateInvoice,
}) => {
  const [isRegenerating, setIsRegenerating] = useState(false);
  const [regeneratedLink, setRegeneratedLink] = useState<string | null>(null);

  const handleRegenerateClick = async (e: React.MouseEvent) => {
    e.preventDefault();

    try {
      setIsRegenerating(true);

      // If no original link, try to regenerate
      const result = await onRegenerateInvoice(doctorId, [invoiceNumber]);

      if (result && Array.isArray(result)) {
        if (result.length > 0) {
          // Extract the URL from the array
          const pdfUrl = result[0];

          // Set the regenerated link
          setRegeneratedLink(pdfUrl);

          // Open the regenerated invoice in a new tab
          window.open(pdfUrl, '_blank');
        } else {
          console.error(
            'No PDF URL returned from the API. Empty array received.'
          );
          alert('No PDF was generated. Please try again or contact support.');
        }
      } else {
        console.error('Invalid response format:', result);
        alert('Failed to regenerate the invoice. Please try again later.');
      }
    } catch (error) {
      console.error('Error regenerating invoice:', error);
      alert('An error occurred while regenerating the invoice.');
    } finally {
      setIsRegenerating(false);
    }
  };

  return (
    <div style={{ textAlign: 'right' }}>
      <a
        href={regeneratedLink || link}
        target='_blank'
        rel='noopener noreferrer'
        onClick={handleRegenerateClick}
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          textDecoration: 'none',
          color: '#323A40',
          cursor: 'pointer',
        }}
      >
        <img
          src='/assets/Download.svg'
          alt='Download'
          style={{
            width: '1.25rem',
            height: '1.25rem',
            marginRight: '0.5rem',
          }}
        />
        {isRegenerating ? 'Loading...' : 'Download'}
      </a>
    </div>
  );
};

export default ActionCell;
