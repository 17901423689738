import * as React from 'react';
import { useState, useEffect, useCallback, useRef } from 'react';
import CompanyService from '../../../../../../services/CompanyService';
import LocalStorageService from '../../../../../../services/LocalStorageService';
import { App } from '../../../../../../app';
import CompanyDTO from '../../../../../../models/CompanyDTO';
import SearchView from '../../../../components/searchView/SearchView';
import ListView from '../../../../components/listView/ListView';
import CompanyViewStyle from './CompanyViewStyle';
import { exportCompanies } from '../../../../../../helpers/ExportHelper';
import { LocalizationContext } from 'src/components/localizationContext/LocalizationContext';
import UserContext from 'src/components/userContext/UserContext';
import RecentItemsView from './components/RecentItemsView';

const CompanyView: React.FC = () => {
  // Constants
  const searchableValues = ['name'];
  const service = new CompanyService();
  const style = new CompanyViewStyle();
  
  // State
  const [currentCompany, setCurrentCompany] = useState('');
  const [elements, setElements] = useState<CompanyDTO[]>([]);
  const [allElements, setAllElements] = useState<CompanyDTO[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);
  const [isBusy, setIsBusy] = useState(false);
  const [settingsHovered, setSettingsHovered] = useState(false);
  const [superiorHovered, setSuperiorHovered] = useState(false);
  const [editHovered, setEditHovered] = useState(false);
  const [myCertificateHovered, setMyCertificateHovered] = useState(false);
  const [recentSearches, setRecentSearches] = useState<string[]>([]);
  const [recentCompanies, setRecentCompanies] = useState<any[]>([]);
  
  const searchTimeout = useRef<NodeJS.Timeout | null>(null);

  // Load companies on mount
  useEffect(() => {
    const fetchCompanies = async () => {
      setIsBusy(true);
      try {
        const companies = await service.get();
        const sortedCompanies = companies?.sort(sortTable);
        const filteredCompanies = sortedCompanies?.filter((elt: CompanyDTO) => elt.name !== 'XXXXXXX') || [];
        setAllElements(filteredCompanies);
        setElements(filteredCompanies);
      } catch (error) {
        console.error('Error fetching companies:', error);
      } finally {
        setIsBusy(false);
      }
    };

    fetchCompanies();
    
    // Load recent searches and companies from local storage
    setRecentSearches(LocalStorageService.getRecentSearches());
    setRecentCompanies(LocalStorageService.getRecentCompanies());
  }, []);

  // Handlers
  const handleCurrentCompanyHovered = useCallback((company: CompanyDTO) => {
    setCurrentCompany(company.id);
  }, []);

  const handleSuperiorsHovered = useCallback(() => {
    setSuperiorHovered(prev => !prev);
  }, []);

  const handleSettingsHovered = useCallback(() => {
    setSettingsHovered(prev => !prev);
  }, []);

  const handleEditHovered = useCallback(() => {
    setEditHovered(prev => !prev);
  }, []);

  const handleMyCertificateHovered = useCallback(() => {
    setMyCertificateHovered(prev => !prev);
  }, []);

  const handleCopied = useCallback(() => {
    alert({ type: 'success', message: 'Copied to clipboard.' });
  }, []);

  const handleCompanyClick = useCallback((company: CompanyDTO, route: string) => {
    // Save to recent companies in local storage
    LocalStorageService.saveRecentCompany(company);
    
    // Update recent companies state
    setRecentCompanies(LocalStorageService.getRecentCompanies());
    
    // Navigate to the company page
    App.history.push(route, { company });
  }, []);

  const handleRecentSearchClick = useCallback((term: string) => {
    setSearchTerm(term);
    filterElements(term);
  }, [allElements]);

  const handleRecentCompanyClick = useCallback((company: any) => {
    // Navigate to company detail page
    App.history.push('/dashboard/company/detail', { company });
  }, []);

  const handleSearchChanged = useCallback((event: React.ChangeEvent<HTMLInputElement>, dontUpdatePage?: boolean) => {
    const term = event.target.value;
    setSearchTerm(term);
    
    // Update page if needed
    if (!dontUpdatePage) {
      setPage(0);
    }
    
    filterElements(term);
  }, [allElements]);
  
  // Handle search input blur - save search term to local storage
  const handleSearchBlur = useCallback(() => {
    if (searchTerm.trim()) {
      LocalStorageService.saveRecentSearch(searchTerm);
      setRecentSearches(LocalStorageService.getRecentSearches());
    }
  }, [searchTerm]);

  // Methods
  const filterElements = useCallback((searchTerm: string) => {
    if (!searchTerm) {
      setElements(allElements);
      return;
    }
    
    const filtered = allElements.filter((element: CompanyDTO) => {
      return searchableValues.some(searchableValue => search(element, searchableValue, searchTerm));
    });
    
    setElements(filtered);
  }, [allElements, searchableValues]);

  const search = useCallback((element: any, searchableValue: string, searchTerm: string) => {
    try {
      if (searchableValue.includes('.')) {
        let value = element;
        searchableValue.split('.').forEach(searchValue => value = value[searchValue]);
        
        return value.toLowerCase().includes(searchTerm.toLowerCase());
      }
      
      return element[searchableValue].toLowerCase().includes(searchTerm.toLowerCase());
    } catch (exception) {
      return false;
    }
  }, []);

  const sortTable = useCallback((a: CompanyDTO, b: CompanyDTO) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name === b.name) {
      return 0;
    }
    return 1;
  }, []);

  const mapHeaders = useCallback(() => {
    return (
      <LocalizationContext.Consumer>
        {({ translate }) => (
          <tr>
            <th scope='col' style={{ width: '20%', overflowX: 'hidden' }}>
              {translate('companyName', 'en')}
            </th>
            <th scope='col' style={{ width: '40%', overflowX: 'hidden' }}>
              {translate('companyAddress', 'en')}
            </th>
            <th scope='col' style={{ width: '20%', overflowX: 'hidden' }}>
              {translate('companySalesRep', 'en')}
            </th>
            <th scope='col' style={{ width: '15%', overflowX: 'hidden' }}>
              {translate('companyNumber', 'en')}
            </th>
            <th scope='col' style={{ width: '5%' }}></th>
          </tr>
        )}
      </LocalizationContext.Consumer>
    );
  }, []);

  const mapCompanies = useCallback((company: CompanyDTO, index: number) => {
    return (
      <tr
        onMouseEnter={() => handleCurrentCompanyHovered(company)}
        key={index}
      >
        <td
          style={{ ...style.td, width: '20%', overflowX: 'hidden' }}
          onCopy={handleCopied}
        >
          {company.name}
        </td>
        <td
          style={{ ...style.td, width: '40%', overflowX: 'hidden' }}
          onCopy={handleCopied}
        >
          {company.address}
        </td>
        <td
          style={{ ...style.td, width: '20%', overflowX: 'hidden' }}
          onCopy={handleCopied}
        >
          {company.salesRepresentative}
        </td>
        <td
          style={{ ...style.td, width: '15%', overflowX: 'hidden' }}
          onCopy={handleCopied}
        >
          {`${company.companyNumber}`}
        </td>
        <td style={{ width: '5%', textAlign: 'right' }}>
          <span
            onMouseEnter={handleEditHovered}
            onMouseLeave={handleEditHovered}
            onClick={() => handleCompanyClick(company, '/dashboard/company/detail')}
            style={style.button}
          >
            <img
              src={`/assets/edit_company${
                editHovered && currentCompany === company.id
                  ? '_selected'
                  : ''
              }.svg`}
              style={style.buttonImage}
            />
          </span>
          <span
            onMouseEnter={handleSuperiorsHovered}
            onMouseLeave={handleSuperiorsHovered}
            onClick={() => handleCompanyClick(company, '/dashboard/company/superior')}
            style={style.button}
          >
            <img
              src={`/assets/edit_superior${
                superiorHovered && currentCompany === company.id
                  ? '_selected'
                  : ''
              }.svg`}
              style={style.buttonImage}
            />
          </span>
          <span
            onMouseEnter={handleSettingsHovered}
            onMouseLeave={handleSettingsHovered}
            onClick={() => handleCompanyClick(company, '/dashboard/company/service')}
            style={style.button}
          >
            <img
              src={`/assets/edit_credits${
                settingsHovered && currentCompany === company.id
                  ? '_selected'
                  : ''
              }.svg`}
              style={style.buttonImage}
            />
          </span>
          {company.hasMyCertificate && (
            <span
              onMouseEnter={handleMyCertificateHovered}
              onMouseLeave={handleMyCertificateHovered}
              onClick={() => handleCompanyClick(company, '/dashboard/company/mycertificate')}
              style={style.button}
            >
              <img
                src={`/assets/edit_mycertificate${
                  myCertificateHovered && currentCompany === company.id
                    ? '_selected'
                    : ''
                }.svg`}
                style={style.buttonImage}
              />
            </span>
          )}
        </td>
      </tr>
    );
  }, [
    currentCompany,
    editHovered,
    superiorHovered,
    settingsHovered,
    myCertificateHovered,
    handleCurrentCompanyHovered,
    handleEditHovered,
    handleSuperiorsHovered,
    handleSettingsHovered,
    handleMyCertificateHovered,
    handleCompanyClick,
    handleCopied,
    style
  ]);

  // Clean up timeout on unmount
  useEffect(() => {
    return () => {
      if (searchTimeout.current) {
        clearTimeout(searchTimeout.current);
      }
    };
  }, []);

  // Styles for recent items
  const recentItemStyles = {
    recentItem: {
      padding: '5px 10px',
      backgroundColor: '#e9ecef',
      borderRadius: '4px',
      cursor: 'pointer',
      fontSize: '14px',
      transition: 'background-color 0.2s',
      ':hover': {
        backgroundColor: '#dee2e6'
      }
    }
  };

  return (
    <LocalizationContext.Consumer>
      {({ translate }) => (
        <UserContext.Consumer>
          {({ isInRole }) => (
            <div>
              {/* Recent Searches and Companies Section - Only show if there are items */}
              {(recentSearches.length > 0 || recentCompanies.length > 0) && (
                <div style={{ marginBottom: 15 }}>
                  {recentSearches.length > 0 && (
                    <div style={{ marginBottom: 10 }}>
                      <h4 style={{ fontSize: 14, marginBottom: 5 }}>{translate('recentSearches', 'en') || 'Recent Searches'}</h4>
                      <div style={{ display: 'flex', flexWrap: 'wrap', gap: 8 }}>
                        {recentSearches.map((searchTerm, index) => (
                          <div 
                            key={index}
                            style={recentItemStyles.recentItem}
                            onClick={() => handleRecentSearchClick(searchTerm)}
                          >
                            {searchTerm}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  
                  {recentCompanies.length > 0 && (
                    <div>
                      <h4 style={{ fontSize: 14, marginBottom: 5 }}>{translate('recentCompanies', 'en') || 'Recent Companies'}</h4>
                      <div style={{ display: 'flex', flexWrap: 'wrap', gap: 8 }}>
                        {recentCompanies.map((company, index) => (
                          <div 
                            key={index}
                            style={recentItemStyles.recentItem}
                            onClick={() => handleRecentCompanyClick(company)}
                          >
                            {company.name}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              )}
              
              {/* Existing SearchView */}
              <SearchView
                title={translate('company')}
                history={[
                  { name: translate('companies'), path: '/dashboard/company' },
                ]}
                handleSearchChanged={handleSearchChanged}
                handleSearchBlur={handleSearchBlur}
                handleNew={() => App.history.push('/dashboard/company/detail')}
                handleSecondaryAction={isInRole('admin') && exportCompanies}
                search={searchTerm}
              >
                <ListView
                  isBusy={isBusy}
                  elements={elements}
                  mapElements={mapCompanies}
                  mapHeaders={mapHeaders}
                />
              </SearchView>
            </div>
          )}
        </UserContext.Consumer>
      )}
    </LocalizationContext.Consumer>
  );
};

export default CompanyView;
