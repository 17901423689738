import React from 'react';
import { DoctorInvoicesProps } from './types';
import { useDoctorInvoicesLogic } from './logic';
import FilterMenu from './components/FilterMenu';
import SearchInput from './components/SearchInput';
import BusyIndicator from './components/BusyIndicator';
import Table from './components/Table';

/**
 * Component for displaying doctor invoices
 */
const DoctorInvoices: React.FC<DoctorInvoicesProps> = ({ doctorId, doctorFullName: propDoctorFullName }) => {
  // Logic
  const {
    rowData,
    filterEndDateRange,
    filterStartDateRange,
    filterInvoiceType,
    setFilterEndDateRange,
    setFilterStartDateRange,
    setFilterInvoiceType,
    handleSearchChange,
    searchValue,
    getInvoiceTypeInfo,
    doctorFullName: logicDoctorFullName,
    isLoading,
    handleRegenerateInvoice,
    selectedInvoices,
    handleSelectInvoice,
    handleSelectAllInvoices,
    handleDownloadSelected,
    isDownloadingSelected,
  } = useDoctorInvoicesLogic(doctorId, propDoctorFullName);

  // Render
  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', width: '100%' }}>
      {/* Header Section */}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '1rem',
          borderBottom: '1px solid #E5E5E5',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ color: '#647480', marginRight: '0.5rem' }}>Doctors</span>
          <span style={{ color: '#323A40', fontWeight: 600 }}>
            {(propDoctorFullName || logicDoctorFullName).toUpperCase()}
          </span>
        </div>
      </div>

      {/* Filter Section */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '1rem',
          borderBottom: '1px solid #E5E5E5',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <FilterMenu
            filterEndDateRange={filterEndDateRange}
            filterStartDateRange={filterStartDateRange}
            filterInvoiceType={filterInvoiceType}
            setFilterEndDateRange={setFilterEndDateRange}
            setFilterStartDateRange={setFilterStartDateRange}
            setFilterInvoiceType={setFilterInvoiceType}
            getInvoiceTypeInfo={getInvoiceTypeInfo}
          />
          
          {selectedInvoices.length > 0 && (
            <button
              onClick={handleDownloadSelected}
              disabled={isDownloadingSelected}
              style={{
                display: 'inline-flex',
                alignItems: 'center',
                padding: '0.5rem 1rem',
                backgroundColor: '#007BFF',
                color: 'white',
                border: 'none',
                borderRadius: '4px',
                cursor: isDownloadingSelected ? 'not-allowed' : 'pointer',
                opacity: isDownloadingSelected ? 0.7 : 1,
              }}
            >
              <img
                src="/assets/Download.svg"
                alt="Download"
                style={{
                  width: '1rem',
                  height: '1rem',
                  marginRight: '0.5rem',
                  filter: 'brightness(0) invert(1)',
                }}
              />
              {isDownloadingSelected
                ? 'Downloading...'
                : `Download Selected (${selectedInvoices.length})`}
            </button>
          )}
        </div>
        <SearchInput
          value={searchValue}
          onChange={handleSearchChange}
          placeholder="Search"
        />
      </div>

      {/* Data Grid Section */}
      <div style={{ flex: 1, overflow: 'auto', padding: '1rem' }}>
        <BusyIndicator isLoading={isLoading}>
          <Table 
            data={rowData} 
            doctorId={doctorId}
            getInvoiceTypeInfo={getInvoiceTypeInfo} 
            onRegenerateInvoice={handleRegenerateInvoice}
            selectedInvoices={selectedInvoices}
            onSelectInvoice={handleSelectInvoice}
            onSelectAllInvoices={handleSelectAllInvoices}
          />
        </BusyIndicator>
      </div>
    </div>
  );
};

export default DoctorInvoices;
