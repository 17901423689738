import React from 'react';

interface BusyIndicatorProps {
  isLoading: boolean;
  children: React.ReactNode;
}

/**
 * Component for showing a loading spinner
 */
const BusyIndicator: React.FC<BusyIndicatorProps> = ({ isLoading, children }) => {
  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
        <div 
          style={{ 
            width: '40px', 
            height: '40px', 
            border: '4px solid rgba(0, 0, 0, 0.1)', 
            borderRadius: '50%', 
            borderTop: '4px solid #0066CC', 
            WebkitAnimation: 'spin 1s linear infinite',
            animation: 'spin 1s linear infinite'
          }} 
        />
      </div>
    );
  }

  return <>{children}</>;
};

// Add a global style for the spinner animation
if (typeof document !== 'undefined') {
  const styleElement = document.createElement('style');
  styleElement.textContent = `
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
    @-webkit-keyframes spin {
      0% { -webkit-transform: rotate(0deg); }
      100% { -webkit-transform: rotate(360deg); }
    }
  `;
  document.head.appendChild(styleElement);
}

export default BusyIndicator;
