import React, { useMemo } from 'react';
import { SummaryDoctorInvoiceDTO } from '../types';
import TypeCell from './TypeCell';
import ActionCell from './ActionCell';
import { format, parseISO } from 'date-fns';

import { TableProps } from '../types';

/**
 * Component for displaying the invoice table
 */
const Table: React.FC<TableProps> = ({
  data,
  doctorId,
  getInvoiceTypeInfo,
  onRegenerateInvoice,
  selectedInvoices,
  onSelectInvoice,
  onSelectAllInvoices,
}) => {
  // Check if all visible invoices are selected
  const allSelected = useMemo(() => {
    return data.length > 0 && data.every(invoice => selectedInvoices.includes(invoice.id));
  }, [data, selectedInvoices]);
  return (
    <div style={{ width: '100%', overflowX: 'auto' }}>
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr style={{ borderBottom: '1px solid #E5E5E5' }}>
            <th
              style={{
                padding: '1rem',
                textAlign: 'center',
                fontWeight: 600,
                color: '#323A40',
                width: '40px',
              }}
            >
              <input
                type="checkbox"
                checked={allSelected}
                onChange={(e) => onSelectAllInvoices(e.target.checked)}
                disabled={data.length === 0}
                style={{ cursor: 'pointer' }}
              />
            </th>
            <th
              style={{
                padding: '1rem',
                textAlign: 'left',
                fontWeight: 600,
                color: '#323A40',
              }}
            >
              Type
            </th>
            <th
              style={{
                padding: '1rem',
                textAlign: 'left',
                fontWeight: 600,
                color: '#323A40',
              }}
            >
              Invoice Date
            </th>
            <th
              style={{
                padding: '1rem',
                textAlign: 'left',
                fontWeight: 600,
                color: '#323A40',
              }}
            >
              Invoice Number
            </th>
            <th
              style={{
                padding: '1rem',
                textAlign: 'left',
                fontWeight: 600,
                color: '#323A40',
              }}
            >
              Amount
            </th>
            <th
              style={{
                padding: '1rem',
                textAlign: 'left',
                fontWeight: 600,
                color: '#323A40',
              }}
            >
              Created By
            </th>
            <th
              style={{
                padding: '1rem',
                textAlign: 'right',
                fontWeight: 600,
                color: '#323A40',
              }}
            >
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {data.length === 0 ? (
            <tr>
              <td
                colSpan={7}
                style={{
                  padding: '2rem',
                  textAlign: 'center',
                  color: '#647480',
                }}
              >
                No invoices found
              </td>
            </tr>
          ) : (
            data.map((invoice) => (
              <tr
                key={invoice.id}
                style={{ 
                  borderBottom: '1px solid #E5E5E5',
                  backgroundColor: selectedInvoices.includes(invoice.id) ? '#F5F9FC' : 'transparent',
                }}
              >
                <td style={{ padding: '1rem', textAlign: 'center' }}>
                  <input
                    type="checkbox"
                    checked={selectedInvoices.includes(invoice.id)}
                    onChange={(e) => onSelectInvoice(invoice.id, e.target.checked)}
                    style={{ cursor: 'pointer' }}
                  />
                </td>
                <td style={{ padding: '1rem' }}>
                  <TypeCell
                    invoice={invoice}
                    getInvoiceTypeInfo={getInvoiceTypeInfo}
                  />
                </td>
                <td style={{ padding: '1rem' }}>
                  {invoice.date
                    ? format(parseISO(invoice.date), 'dd/MM/yyyy - HH:mm')
                    : ''}
                </td>
                <td style={{ padding: '1rem' }}>{invoice.number}</td>
                <td style={{ padding: '1rem' }}>{invoice.amount}€</td>
                <td style={{ padding: '1rem' }}>
                  {invoice.createdBy?.firstName && invoice.createdBy?.lastName
                    ? `${invoice.createdBy.firstName.toUpperCase()} ${invoice.createdBy.lastName.toUpperCase()}`
                    : 'N.A'}
                </td>
                <td style={{ padding: '1rem' }}>
                  <ActionCell
                    link={invoice.link}
                    invoiceNumber={invoice.number}
                    doctorId={doctorId}
                    onRegenerateInvoice={onRegenerateInvoice}
                  />
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
