import * as React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import DoctorDetailView from './detail/DoctorDetailView';
import DoctorList from './list';
import DoctorInvoices from './detail/modules/dashboard-invoices/Doctorinvoices';

export default class DoctorShellView extends React.Component {
  /* RENDERING */
  render() {
    return (
      <Switch>
        <Route exact path='/dashboard/doctor' render={() => <DoctorList />} />
        {/* <Route exact path="/dashboard/doctor" render={() => <DoctorView />} /> */}
        <Route
          path='/dashboard/doctor/detail'
          render={() => <DoctorDetailView />}
        />

        <Route
          path='/dashboard/doctor/invoices/:doctorId'
          render={({ match, location }) => (
            <DoctorInvoices
              doctorId={match.params.doctorId}
              doctorFullName={(location.state as any)?.doctorFullName}
            />
          )}
        />

        <Route
          path='/dashboard/doctor'
          render={() => <Redirect to='/dashboard/doctor' />}
        />
      </Switch>
    );
  }
}
