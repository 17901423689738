/**
 * Enum representing the type of invoice
 */
export enum InvoiceType {
    INVOICE = 0,
    CREDIT_NOTE = 1
}

/**
 * Interface for the summary of a doctor invoice
 */
export interface SummaryDoctorInvoiceDTO {
    id: string;
    type: InvoiceType;
    date: string;
    number: string;
    amount: number;
    link: string;
    createdBy: {
        id: string;
        firstName: string;
        lastName: string;
    };
}

/**
 * Props for the DoctorInvoices component
 */
export interface DoctorInvoicesProps {
    doctorId: string;
    doctorFullName?: string; // Optional to maintain backward compatibility
}

/**
 * Response from the DoctorInvoices logic hook
 */
export interface DoctorInvoicesLogicResponse {
    rowData: SummaryDoctorInvoiceDTO[];
    filterEndDateRange: Date | null;
    filterStartDateRange: Date | null;
    filterInvoiceType: InvoiceType | null;
    setFilterEndDateRange: (date: Date | null) => void;
    setFilterStartDateRange: (date: Date | null) => void;
    setFilterInvoiceType: (type: InvoiceType | null) => void;
    handleSearchChange: (value: string) => void;
    searchValue: string;
    getInvoiceTypeInfo: (type: InvoiceType) => {
        backgroundColor: string;
        textColor: string;
        label: string;
    };
    doctorFullName: string;
    isLoading: boolean;
    handleRegenerateInvoice: (doctorId: string, invoiceReferences: string[]) => Promise<any>;
    selectedInvoices: string[];
    handleSelectInvoice: (invoiceId: string, isSelected: boolean) => void;
    handleSelectAllInvoices: (isSelected: boolean) => void;
    handleDownloadSelected: () => Promise<void>;
    isDownloadingSelected: boolean;
}

/**
 * Props for the TypeCell component
 */
export interface TypeCellProps {
    invoice: SummaryDoctorInvoiceDTO;
    getInvoiceTypeInfo: (type: InvoiceType) => {
        backgroundColor: string;
        textColor: string;
        label: string;
    };
}

/**
 * Props for the ActionCell component
 */
export interface ActionCellProps {
    link: string;
    invoiceNumber: string;
    doctorId: string;
    onRegenerateInvoice: (doctorId: string, invoiceReferences: string[]) => Promise<any>;
}

/**
 * Props for the Table component
 */
export interface TableProps {
  data: SummaryDoctorInvoiceDTO[];
  doctorId: string;
  getInvoiceTypeInfo: (type: number) => {
    backgroundColor: string;
    textColor: string;
    label: string;
  };
  onRegenerateInvoice: (
    doctorId: string,
    invoiceReferences: string[]
  ) => Promise<any>;
  selectedInvoices: string[];
  onSelectInvoice: (invoiceId: string, isSelected: boolean) => void;
  onSelectAllInvoices: (isSelected: boolean) => void;
}

/**
 * Props for the DateRangeFilter component
 */
export interface DateRangeFilterProps {
    filterEndDateRange: Date | null;
    filterStartDateRange: Date | null;
    setFilterEndDateRange: (date: Date | null) => void;
    setFilterStartDateRange: (date: Date | null) => void;
    onFilterClose: () => void;
}

/**
 * Props for the TypeInvoiceFilter component
 */
export interface TypeInvoiceFilterProps {
    filterInvoiceType: InvoiceType | null;
    setFilterInvoiceType: (type: InvoiceType | null) => void;
    getInvoiceTypeInfo: (type: InvoiceType) => {
        backgroundColor: string;
        textColor: string;
        label: string;
    };
    onFilterClose: () => void;
}

/**
 * Props for the FilterMenu component
 */
export interface FilterMenuProps {
    filterEndDateRange: Date | null;
    filterStartDateRange: Date | null;
    filterInvoiceType: InvoiceType | null;
    setFilterEndDateRange: (date: Date | null) => void;
    setFilterStartDateRange: (date: Date | null) => void;
    setFilterInvoiceType: (type: InvoiceType | null) => void;
    getInvoiceTypeInfo: (type: InvoiceType) => {
        backgroundColor: string;
        textColor: string;
        label: string;
    };
}
