import React, { useState, useMemo, useCallback } from 'react';
import { useQuery } from 'react-query';
import { App } from 'src/app';
import { LocalizationContext } from 'src/components/localizationContext/LocalizationContext';
import UserContext from 'src/components/userContext/UserContext';
import useSearch from 'src/components/useSearch';
import DoctorDTO from 'src/models/DoctorDTO';
import BusyIndicator from 'src/modules/dashboard/components/busyIndicator';
import DoctorService from 'src/services/DoctorService';
import DoctorListTable from './modules/table';
import SearchableView from "../../../../dashboard/components/searchView";
import { exportDoctor } from 'src/helpers/ExportHelper';
import ToggleView from 'src/modules/dashboard/components/toggleView/ToggleView';
import DoctorViewStyle from './DoctorViewStyle';
import DoctorsMap from './modules/table/map';

const DoctorList = () => {

    // Attributes - memoize service and style to prevent recreation on each render
    const service = useMemo(() => new DoctorService(), []);
    const style = useMemo(() => new DoctorViewStyle(), []);
    
    // Handlers - memoize function to prevent recreation on each render
    const refreshDoctors = useCallback(async () => {
        return (await service.get());
    }, [service]);
    
    const doctorQuery = useQuery("doctors", refreshDoctors);
    
    // Memoize the data and search fields to prevent unnecessary re-renders
    const memoizedDoctorData = useMemo(() => doctorQuery.data ?? [], [doctorQuery.data]);
    const searchFields = useMemo(() => ["firstName", "lastName", "fullName", "email"], []);
    
    const { search, setSearch, filtered } = useSearch<DoctorDTO>(memoizedDoctorData, searchFields);
    const [isMap, setIsMap] = useState(false);
    
    // Memoize handlers to prevent recreation on each render
    const handleSearchChanged = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    }, [setSearch]);
    
    const handleNew = useCallback(() => {
        App.history.push('/dashboard/doctor/detail');
    }, []);

    // Memoize the toggle handler
    const handleIsToggled = useCallback(() => {
        setIsMap(previous => !previous);
    }, []);
    
    const ToggleButton = useCallback(() => {
        return (
            <div style={style.toggleStyle}>
                <ToggleView yesTitle={"Map"} noTitle={"List"} isToggled={isMap} handleIsToggled={handleIsToggled} />
            </div>
        )
    }, [style, isMap, handleIsToggled]);

    return (
        <LocalizationContext.Consumer>
            {({ translate }) => (
                <UserContext.Consumer>
                    {({ isInRole }) => (
                        <SearchableView
                            title={translate('doctorTitle')}
                            history={[{ name: translate('doctorTitle'), path: "/dashboard/doctor" }]}
                            handleSearchChanged={handleSearchChanged}
                            search={search}
                            handleNew={handleNew}
                            handleSecondaryAction={isInRole('admin') && exportDoctor}
                            secondaryActionChildren={ToggleButton}

                        >
                            <BusyIndicator query={doctorQuery}>

                                {
                                    isMap
                                        ?

                                        (
                                            <DoctorsMap doctors={filtered} />
                                        )
                                        :
                                        (
                                            <DoctorListTable doctors={filtered} />
                                        )
                                }
                            </BusyIndicator>
                        </SearchableView>
                    )}
                </UserContext.Consumer>
            )}
        </LocalizationContext.Consumer>
    )
};

export default DoctorList;
